@import url(https://cdn.rawgit.com/mfd/f3d96ec7f0e8f034cc22ea73b3797b59/raw/856f1dbb8d807aabceb80b6d4f94b464df461b3e/gotham.c);
@import url(//db.onlinewebfonts.com/c/3a47f5f06b5484abfeee9eac90348a9c?family=Gotham);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css);

body {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
}
Nav a {
    color: #502773;
}
.logo{
    padding: 40px 30px 30px 5px;

  }
.color a{
    color: #502773 ;
}
.active{
    color: #502773 ;
}
.active:active{
    color: #502773;
}
.menu{
    
    font-family: "TruenoRegular", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin-top: 6px;
    /* identical to box height, or 111% */
    
}
.menu:hover {
    color: #fff;
}

 .nav-link:hover {
    background: #502773;
    color: #fff !important;
}

.nav-link h5:hover{
   
}

.logo-container {
    padding: 60px;
}

@media (max-width: 992px) {
    .nav-link {
  
      padding: 10px;}
   
    .navbar-collapse.collapsing .navbar-nav {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: -45%;
        transition: all 0.2s ease;
    }

    .navbar-collapse.show .navbar-nav {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        flex-direction: column;
        height: auto;
        width: 45%;
        transition: left 0.35s ease;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        background: #fff;
        padding: 15px;
    }

    .logo-container {
        padding: 0;
    }
}

.nav-container {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

body{
    
}

.BgTop{
    background-image: url(/static/media/asian-elderly.55e24b5b.png) no-repeat;
    height: 550px;
}
.about{
    margin-left: 300px;
    margin-top: 239px;
    max-width: 782px;
 }
.row{
    margin-right: 0px;
    margin-left: 0px;
}

.BgTop{
    background: url(/static/media/asian-elderly.55e24b5b.png) no-repeat center;     
    background-size: cover;
    min-height: 86vh;
    overflow: hidden;
}

.footer b{
    font-family: "TruenoBold", Arial, sans-serif;
    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    /* identical to box height, or 117% */

    color: #F3CA04;
 }
.section4 h3{
    font-family: "TruenoBold", Arial, sans-serif;
    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 38px;
    /* identical to box height, or 53% */
    /* margin-top: 60px;
    margin-bottom: 15px;
    margin-right: 10px; */
    color: #fff;
    /* padding: 0 40px 10px 40px; */
    /* text-align: center; */
 }

 .section4 p{     
    /* margin-bottom: 50px; */
    /* padding: 0 40px 40px 40px; */
    /* font-family: 'Gotham'; */
    font-family: "TruenoRegular", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    text-align: start;
    color:  #fff;
    /* padding-bottom: 20px; */
}

h5{
    font-family: "TruenoBold", Arial, sans-serif;
    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 40px;
    /* identical to box height, or 53% */

    display: flex;
    align-items: center;

    /* PRIMARY VIOLET */

    color: #502773;
    margin-bottom: 30px;
}

.botRight {
    background-image:
    linear-gradient(rgba(75, 18, 122, 0.76),rgba(72, 39, 99, 0.63)),
    url(/static/media/CARA_iphone_mockup.b42d7886.png);
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    /* padding: 100px 100px 160px; */
    /* text-align: center; */
    padding: 6% 8%;
 }

.section3 p{
    font-family: "TruenoRegular", Arial, sans-serif;
    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    /* or 140% */
    
    text-align: center;
    
    /* TXT STD */
    
    color: #424242;
}

.splash {
    position: absolute;
    width: 1196px;
    height: 1170px;
    left: -516px;
    top: -485px;
    background: rgba(80, 39, 115, 0.95);
    border-radius: 576px;
}
    
.footer h4{
        
    padding-top: 30px;

    text-align: center;
    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    
    font-family: "TruenoBold", Arial, sans-serif;
    color: #FFFFFF;
}
@media (max-width: 1100px){
   
    .splash{
        width: 976px;
        height: 930px;
        left: -506px;
        top: -475px;
    }
    
}

@media (max-width:700px){
    
    .splash{
        width: 976px;
        height: 910px;
        left: -536px;
        top: -495px;
        
    }
    
}
@media(max-width:500px){
    .splash{
        width: 1034px;
        height: 890px;
        left: -536px;
        top: -495px;
        
    }
} 

.BgTop h2{  position: absolute;
    width: auto;
    height: 110px;
    margin-left: 25px;
    margin-top: 20px;
    

    font-family: "TruenoBold", Arial, sans-serif;
    font-size: 28px;
    /* or 114% */
    
    color: #FFFFFF;

}

@media(max-width:500px) {
    .BgTop h2 {
        width: 80%;
    }
}

.BgTop p{
    position: absolute;
    width: 267px;
    height: 84px;
    margin-left: 30px;
    margin-top: 155px;

    font-family: "TruenoRegular", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 18px;
    /* or 140% */


    color: #FFFFFF;
}
.button{
    position: absolute;
    width: 143px;
    height: 40px;
    margin-left: 30px;
    margin-top: 240px;
    
    font-family: "TruenoBold", Arial, sans-serif;
    color: #502773;
    
    font-size: 14px;
    background: #FFCF03;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
   
.about{
    position: relative;
    margin-left: 40px;
    margin-top: 239px;
    margin-bottom: 200px;
    max-width: 527px;
 }
 .about p{
    padding-right: 10px;
    font-family: "TruenoRegular", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */


    /* TXT STD */

    color: #424242;      
 }

 .about span{
     
    font-weight: bold;
 }
 .content{display:flex;justify-content:space-evenly}
 @media(min-width:500px){
   
    .BgTop h2{
        position: absolute;
        width: 217px;
        height: 70px;
        margin-left: 60px;
        margin-top: 35px;

        font-family: "TruenoBold", Arial, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
    }
    .BgTop p{
        position: absolute;
        max-width: 277px;
        height: 184px;
        margin-left: 60px;
        margin-top: 125px;

        font-size: 15px;
        line-height: 23px;
        font-family: "TruenoRegular", Arial, sans-serif;
        /* or 140% */
    
    
        color: #FFFFFF;
    }
    .button{
        font-family: "TruenoBold", Arial, sans-serif;
        position: absolute;
        width: 145px;
        height: 40px;
        margin-left: 60px;
        margin-top: 229px;
    
        font-size: 14px;

        
        
    }
       
 }
 
 @media(min-width:1100px){
    .about p{
        font-family: "TruenoRegular", Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        /* or 140% */
        
        
        /* TXT STD */
        
        color: #424242;
        }
    .BgTop h2{
        position: absolute;
        width: 407px;
        height: 110px;
        margin-left: 130px;
        margin-top: 35px;

        font-family: "TruenoBold", Arial, sans-serif;
        font-size: 30px;
        line-height: 35px;
        display: flex;
        align-items: center;
    }
    .BgTop p{
        position: absolute;
        width: 497px;
        height: 84px;
        margin-left: 130px;
        margin-top: 175px;

        font-size: 18px;
        line-height: 26px;
        /* or 140% */
    
    
        color: #FFFFFF;
    }
    .button{
        font-family: "TruenoBold", Arial, sans-serif;
        position: absolute;
        width: 203px;
        height: 50px;
        margin-left: 130px;
        margin-top: 329px;
    
        font-size: 18px;

        
        
    }
       
 }
 
 @media (min-width:600px){ 
    .about{
        position: relative;
        margin-left: 100px;
        margin-top: 109px;
        margin-bottom: 200px;
        max-width: 1024px;
     }
      
 }
 /* #about1{
     position: relative;
 }
 #about{
    position: absolute;
    top:-100px;
    left:0;
    height: 28px;
    width: 10px;
 } */
 .Top{
     
    background: linear-gradient(259.05deg, #DDF2E1 41.9%, #F2C903 254.27%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
 }
 .breakPoint{
    background-image: url(/static/media/Pict4.ed6167ce.png);
    background-size: cover;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    
    align-items: center;
    text-align: center;
 }
 .breakPoint h3{
    margin-top: 50px;
    font-family: "TruenoBold", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 40px;
    /* identical to box height, or 53% */
    
    align-items: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px; 
    
    padding-top: 50px; 
    color: #000;
 }
.breakPoint p{
    padding-bottom: 10px;  
    font-family: "TruenoRegular", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */
    width: 800px;
    margin: 0 auto 20px;

    text-align: center;

    color: #000;
}
.breakPoint button{
    width: 200px;
    height: 40px;
    margin-bottom: 40px;

    color: white;
    font-family: "TruenoBold", Arial, sans-serif;
    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    background: #502773;
    border-radius: 5px;
    text-align: center;
}

.section2{
width: auto;
height: auto;
/* padding: 170px 4% 232px; */
padding: 10% 4% 10%;
background: #FBFBFB;
display: flex;
flex: 1 1;
}
.splash2 {position: absolute;
    z-index: 999;
    width: 400px;
    height: 530px;
    right: -106px;
    top: -485px;
    
    background: rgba(80, 39, 115, 0.95);
    border-radius: 576px;
    }
.section2 h3{
    font-family: "TruenoBold", Arial, sans-serif;
   /* font-family: 'Gotham'; */
   font-style: normal;
   font-weight: bold;
   font-size: 38px;
   line-height: 40px;
   /* identical to box height, or 53% */
   margin-top: 40px;
   align-items: center;
   text-align: center;
   width: auto;
   padding: 10px;
   color: #000;
}
.section2 p{
    margin-top: 50px;
    margin-bottom: 50px;

    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */


    color:  #502773;
}
.section2 button{
   width: 180px;
   height: 50px;
   
   font-family: "TruenoBold", Arial, sans-serif;
   color: white;
   /* font-family: 'Gotham'; */
   font-style: normal;
   font-weight: bold;
   font-size: 18px;
   line-height: 28px;
   background: #502773;
   border-radius: 5px;
}
.section3{
    
    width: auto; 
    height: -webkit-fit-content; 
    height: -moz-fit-content; 
    height: fit-content;
    padding-top: 100px;
    padding-bottom: 100px;
    
    background: linear-gradient(211.94deg, #FFE14E 12.79%, #F2C903 80.8%);
}

.section3 button{
    width: 180px;
    height: 50px;
    
 
    color: white;
    font-family: "TruenoBold", Arial, sans-serif;
    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    background: #502773;
    border-radius: 5px;
 }
.section3 h2{
    width: auto;
    height: 20px;
    font-family: "TruenoBold", Arial, sans-serif;
    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 38px;
    margin: auto;
    margin-bottom: 40px;
    /* identical to box height, or 53% */
    
    text-align: center;
    /* TXT STD */
    
    color:#502773;

}

@media (max-width:504px){
      
.section2{
    
    padding-top: 50px;
    padding-bottom: 70px;
    }
.about{
  
    margin-left: 20px; 
    margin-top: 10px;
    margin-bottom: 20px;
 }
    .section3 p{
       height: 80px;
       
       font-size: 10px;
       line-height: 18px;
    }
    
    .breakPoint h3{
        padding:10px;
        font-family: "TruenoBold", Arial, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        /* or 117% */
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
    }
    
    .breakPoint p   {
        font-family: "TruenoRegular", Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        /* or 129% */
        width: auto;
        text-align: center;
                
        color: #000000;
    }
}

@media (max-width:820px){
   
.section3 h2{
    height: 20px;
    font-size: 30px;
    line-height: 30px;
    margin: auto;
    margin-bottom: 60px;
    /* identical to box height, or 53% */
    
    text-align: start;
    /* TXT STD */
    
} 
.section3 p{
   width: auto;
   text-align: start;
   font-size: 18px;
   line-height: 18px;
}

.section4 h3{
    font-size: 24px;
    line-height: 26px;
    color: #FFFFFF;
    text-align: center;
    /* margin: 60px auto 0; */
}
.section4 p{
    
font-size: 14px;
line-height: 18px;
/* or 129% */

text-align: center;

color: #FFFFFF;
}
.section4 button{
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
}
}
.section4{
    /* width: auto;
    height: auto;
    margin: auto; */
    /* text-align:center; */
    /* align-items: center; */
}
 @media (max-width:550px){
    .section4 p{
        margin-left: auto;
    }
     
    .section4 button{
        margin: 0px;
    }
 }
 .CardIcon{
    justify-content: center;
    margin-top: 20px;
 }
 @media (max-width:540px){
    .CardIcon{
        
    } }
 .section4 button{
    width: 200px;
    height: 50px;
    
    color: #502773;
    font-family: "TruenoBold", Arial, sans-serif;
    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    background: #F2C703;;
    border-radius: 5px;
 }


 .botLeft{
   
    background-image:
    linear-gradient(rgba(118, 83, 147, 0.92),rgba(118, 83, 147, 0.92)),
    url(/static/media/asian-elderly-BlackWhite.2a8875a7.png);
 
    background-size: cover;
    /* padding: 100px 100px 160px; */
    /* text-align: left; */
    /* background-color: green; */

    padding: 6% 8%;
    font-family: "TruenoRegular", Arial, sans-serif;
    color: white;
 }
 .botLeft ul li{
    /* font-weight: bold; */
    margin-bottom: 3%;
    list-style:none;
 }
 .botLeft ul li:before{
    content: '- ';
 }
 .botLeft p{
     display: flex;
     justify-content: flex-start;
     font-family: "TruenoRegular", Arial, sans-serif;
    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    /* identical to box height, or 117% */

    color: #F3CA04;
    /* margin-top: 20px; */
    

 }
 .botLeft h3{
    text-align: left;
 }
label{

    font-family: "TruenoBold", Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    /* or 140% */
    color: #FFFFFF;
    
}
@media (max-width:950px){
    .botLeft p{
       
       /* margin: 80px;  */
    }
   label{
    font-size: 16px;
   padding-left: 20px;
   }
}

@media (max-width:800px){
    .botLeft p{
       
       /* margin: 20px;  */
    }
   label{
       text-align: start;
       padding-bottom: 10px;
   }
}
.footer{
    font-family: "TruenoBold", Arial, sans-serif;
    width: auto;

    background: #434343;
    padding: 60px 0;
    
}

@media (min-width: 990px) {
    .faq-container {
        margin-left: 2%;
    }
}
a{
    color: #FFFFFF;
}
a:hover{
    color: #FFFFFF;
    text-decoration: none;
}

.anchor{
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}

@media(max-width:500px){
    
    .section4 h3{
        /* margin-top: 40px; */
     }
     .section4 p{
         
        
     /* padding:20px; */
         }
        .BgTop{
            background:  url(/static/media/asian-elderly.55e24b5b.png);     
            background-size: cover;
     background-position: top;
     background-repeat: no-repeat;
        }
     .section4 button{
         padding-bottom: 80px;
     }   
     .footer span{
        font-size: 14px;
        line-height: 28px;
        /* identical to box height, or 117% */
    
        color: #F3CA04;
        font-family: "TruenoBold", Arial, sans-serif;
     }
    .footer h4{
            
    /* font-family: 'Gotham'; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    font-family: "TruenoBold", Arial, sans-serif;
    /* identical to box height, or 200% */
    
    
    color: #FFFFFF;
    }
       .section3 h2 {
            font-family: "TruenoBold", Arial, sans-serif;
            font-size: 24px;
            line-height: 26px;
            /* or 108% */
            text-align: start;
    
            /* PRIMARY VIOLET */
    
            color: #502773;
        }
       .section3 p{
           
    font-size: 14px;
    line-height: 18px;
    /* or 129% */
    
    
    /* TXT STD */
    
    color: #424242;
       }
        .splash{
            width: 780px;
            height: 940px;
            left: -378px;
            top: -495px;
        }
    
        .BgTop{
            background:  url(/static/media/bg-mobile.a08900d1.png);     
            background-size: cover;
            background-repeat: no-repeat;
            background-position-y: 100px;
        }
        
        .splash button{
            width: 140px;
            
        }
        .section2 h5{
            /* margin-left: 50px; */
            font-size: 24px;
            line-height: 26px;
        }
        .about h5{
            font-size: 24px;
            line-height: 20px;
            /* identical to box height, or 83% */
            
            display: flex;
            align-items: center;
            
            /* PRIMARY VIOLET */
            
            color: #502773;
        }
        
        .section2 button{
           width: 150px;
           height: 30px;
           
           font-family: "TruenoBold", Arial, sans-serif;
           color: white;
           font-family: "TruenoBold", Arial, sans-serif;
           /* font-family: 'Gotham'; */
           font-style: normal;
           font-weight: bold;
           font-size: 14px;
           line-height: 24px;
           background: #502773;
           border-radius: 5px; 
        }
       
        .botRight{
            padding: 15% 8%;
        }
        .botLeft{
            padding: 15% 8%;
        }
        /* .botLeft ul li{
            text-align: justify;
        } */
    } 
.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
/* @import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css"); */
/* @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"); */

/* ==========================================================================
   Responsive images and box padding
   ========================================================================== */
   /* @font-face {
    font-family: 'Gotham';
    src: local('Gotham'), url(./assets/fonts/GothamMedium.ttf) format('truetype');
  }
  @font-face {
    font-family: 'NunitoSans';
    src: local('NunitoSans'), url(./assets/fonts/NunitoSans-Regular.ttf) format('truetype');
  } */
img,
object {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto;
}
.select img,
object {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto;
}
* {
  -moz-box-sizing: border-box;
  -webkit-kit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

body {
  /* font-family: "TruenoUltralight", Arial, sans-serif; */
  /* font-size: 100%; */
  background: #fefefe;
}

/* b, strong {
  font-family: "TruenoBold", Arial, sans-serif;
  font-weight: bolder;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "TruenoRegular", Arial, sans-serif;
} */

.container {
  /* width: 80%; */
  margin: 60px auto;
}

/* Hide arrow input type number - start */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Hide arrow input type number - end */
